export default function (value) {
  let days = Math.floor(value / 86400)
  let hours = Math.floor(value % 86400 / 3600)
  let minutes = Math.floor(value % 3600 / 60)
  let seconds = value % 60

  days = days > 0 ? days + 'd ' : ''
  hours < 10 && (hours = '0' + hours)
  minutes < 10 && (minutes = '0' + minutes)
  seconds < 10 && (seconds = '0' + seconds)

  return `${days}${hours}:${minutes}:${seconds}`
}
